import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import {
  InputGroup,
  InputRightElement,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { useMessageDispatch, useMessageState } from "../context/messages";

interface SearchBarProps {
  setSearch: Dispatch<SetStateAction<string>>;
  search: string;
}
function SearchBar(props: SearchBarProps) {
  const { focusSearchBar }: { focusSearchBar: boolean } = useMessageState();
  const searchbar = useRef(null);
  const messageDispatch = useMessageDispatch();

  //FIXME: running several times
  useEffect(() => {
    if (focusSearchBar && searchbar.current) {
      searchbar.current.focus();
    }
  }, [focusSearchBar]);

  return (
    <InputGroup mb={4}>
      <InputRightElement
        children={
          <IconButton
            variant="unstyled"
            aria-label="Clear search"
            icon={<SmallCloseIcon />}
            onClick={(_) => props.setSearch("")}
          />
        }
      />
      <Input
        onBlur={(_) => {
          messageDispatch({
            type: "TOGGLE_SEARCH",
            payload: false,
          });
        }}
        ref={searchbar}
        value={props.search}
        onChange={(e) => props.setSearch(e.target.value)}
        placeholder="Search"
      />
    </InputGroup>
  );
}

export default SearchBar;
