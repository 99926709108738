import { useEffect } from "react";
import { Avatar } from "@chakra-ui/react";
import { Message, Token, User } from "../types/interfaces";
import { useAuthState } from "../context/auth";
import { useMessageState } from "../context/messages";
import { emotionColors, sentimentColors } from "../constants";
import { isSentimentAppVersion } from "../utils/utils";

interface EmotionAvatarProps {
  latestMessage?: Message;
  name: string;
  username: string;
  onClick?: () => void;
}

function EmotionAvatar(props: EmotionAvatarProps) {
  const { decodedUserToken }: { decodedUserToken: Token } = useAuthState();
  const { users }: { users: User[]; selectedUsername: string } =
    useMessageState();

  const potentialUser = users.filter(
    (user: User) => user.username === props.username
  )[0];
  let currentEmotion = null;
  let currentSentiment = null;
  if (potentialUser) {
    currentEmotion = potentialUser.currentEmotion;
    currentSentiment = potentialUser.currentSentiment;
  }

  //useEffect(() => console.log(currentEmotion), [currentEmotion]);

  const emotionImages = {
    happy:
      "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/271/grinning-face-with-big-eyes_1f603.png",
    sad: "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/271/sad-but-relieved-face_1f625.png",
    anger:
      "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/271/pouting-face_1f621.png",
    disgust:
      "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/271/nauseated-face_1f922.png",
    fear: "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/271/fearful-face_1f628.png",
    neutral:
      "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/271/neutral-face_1f610.png",
    surprise:
      "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/271/face-with-open-mouth_1f62e.png",
  };

  if (
    props.latestMessage &&
    currentEmotion &&
    currentSentiment
    // props.latestMessage.sender != decodedUserToken.username
  ) {
    return (
      <Avatar
        // src={emotionImages[currentEmotion]}
        name={props.name}
        borderColor={
          isSentimentAppVersion()
            ? sentimentColors[currentSentiment]
            : emotionColors[currentEmotion]
        }
        borderWidth="4px"
        backgroundColor="brand.grey.200"
        size="md"
        showBorder={true}
        mr={2}
        onClick={props.onClick}
      />
    );
  } else {
    return (
      <Avatar name={props.name} size="md" mr={2} onClick={props.onClick} />
    );
  }
}

export default EmotionAvatar;
