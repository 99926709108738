import { createContext, useReducer, useContext } from "react";
import jwtDecode from "jwt-decode";
import { Token, User, Message } from "../types/interfaces";

const AuthStateContext = createContext(null);
const AuthDispatchContext = createContext(null);

let decodedUserToken: Token = null;
let user: User = {
  name: localStorage.getItem("name"),
  messages: [] as Message[],
};
const localToken: string = localStorage.getItem("token");

if (localToken) {
  decodedUserToken = jwtDecode(localToken);
  const expiresAt = new Date(decodedUserToken.exp * 1000);
  if (new Date() > expiresAt) {
    localStorage.removeItem("token");
    decodedUserToken = null;
  }
}

const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("name", action.payload.name);
      return {
        ...state,
        decodedUserToken,
        user,
      };
    case "LOGOUT":
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("readMessages"); //FIXME: this means that if you logout and log back in all your unread messages become read
      // Temporary fix: assign a user to the readMessages object
      // Long term fix: move read messages to backend/DB
      return {
        ...state,
        decodedUserToken: null,
        user: null,
      };
    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export const AuthProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(authReducer, {
    decodedUserToken,
    user,
  });

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);
export const useAuthDispatch = () => useContext(AuthDispatchContext);
