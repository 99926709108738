import { useEffect, useRef, useState, useLayoutEffect } from 'react'
import {
	Box,
	VStack,
	HStack,
	IconButton,
	Textarea,
	useBreakpointValue,
	Spacer,
	Button,
	Text,
} from '@chakra-ui/react'
import { gql, useLazyQuery, useMutation } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faSmile } from '@fortawesome/free-solid-svg-icons'
import ResizeTextarea from 'react-textarea-autosize'

import { useMessageDispatch, useMessageState } from '../context/messages'
import { User, Message as MessageType } from '../types/interfaces'
import Message from '../components/Message'

interface ConversationProps {}
function Conversation(props: ConversationProps) {
	const {
		users,
		selectedUsername,
	}: { users: User[]; selectedUsername: string } = useMessageState()
	const messagesEndRef = useRef(null)
	const dispatch = useMessageDispatch()
	const [currentMessage, setCurrentMessage] = useState('')
	const [emojis, setEmojis] = useState('')
	const [dict, setDict] = useState(null) // set to dictDetails obj

	const messages =
		users.filter((u: User) => u.username === selectedUsername)[0]?.messages ??
		[]

	// https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			//FIXME: runs 3 times
			// console.log("Scrolling to bottom");
			// Scroll to bottom of messages
			messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
			// messagesEndRef.current = false;
			return
		}
	}
	useLayoutEffect(() => {
		scrollToBottom()
	}, [users])

	const GET_MESSAGES = gql`
		query getMessages($otherUsername: String!) {
			getMessages(otherUsername: $otherUsername) {
				id
				sender
				content
				timestamp
				emotion
				emojis
				emotionOriginal
				sentiment
				hasDefinition
				defQueryIds
			}
		}
	`
	const [
		getMessages,
		{ loading: messagesLoading, data: messagesData, error: messageError },
	] = useLazyQuery(GET_MESSAGES)

	useEffect(() => {
		if (selectedUsername) {
			// getMessages({ variables: { otherUsername: selectedUser.username } });
			getMessages({ variables: { otherUsername: selectedUsername } })
		}
	}, [selectedUsername])
	useEffect(() => {
		if (messageError) console.log(messageError)
		if (messagesData && selectedUsername) {
			dispatch({
				type: 'SET_USER_MESSAGES',
				payload: {
					username: selectedUsername,
					messages: messagesData.getMessages,
				},
			})
		}
	}, [messagesData, messageError])

	const SEND_MESSAGE = gql`
		mutation sendMessage($recipient: String!, $content: String!) {
			sendMessage(recipient: $recipient, content: $content) {
				id
				sender
				recipient
				content
				timestamp
			}
		}
	`
	const [sendMessage] = useMutation(SEND_MESSAGE, {
		onError: (err) => console.log(err),
	})
	const submitMessage = (e?) => {
		if (currentMessage.trim() === '') return
		sendMessage({
			variables: { recipient: selectedUsername, content: currentMessage },
		})
		setCurrentMessage('')
		setEmojis('')
	}

	let messagesMarkup: any
	if (messagesLoading) {
		messagesMarkup = <Box></Box>
	} else if (!messagesLoading && selectedUsername && messages.length > 0) {
		//FIXME: remove any type
		messagesMarkup = messages.map((msg: MessageType, i: number) => {
			return <Message message={msg} key={i} dict={dict} setDict={setDict} />
		})
	} else if (!messagesLoading && selectedUsername && messages.length === 0) {
		// messagesMarkup = <p>Error</p>;
	}

	// EMOJI SUGGESTION CODE
	const GET_EMOJIS = gql`
		query getEmojis($text: String!) {
			getEmojis(text: $text)
		}
	`

	const [emojiQuery, { loading: emojisLoading }] = useLazyQuery(GET_EMOJIS, {
		onCompleted: (data) => setEmojis(data.getEmojis),
		onError: (err) => console.log(err),
	})
	useEffect(() => {
		const delayEmojiSuggestions = setTimeout(() => {
			// Send emoji suggestion request
			// After user stops typing for 2 seconds
			if (currentMessage.length >= 4) {
				emojiQuery({ variables: { text: currentMessage } })
			}
		}, 2000)
		return () => clearTimeout(delayEmojiSuggestions)
	}, [currentMessage])

	let emojiMarkup: any
	if ((!emojisLoading && emojis === '') || currentMessage.length === 0) {
		// No text typed yet
		emojiMarkup = <p>Start typing to see emoji suggestions</p>
	} else if (emojisLoading) {
		// Getting emojis
		emojiMarkup = <p>Loading...</p>
	} else if (emojis.length > 0) {
		const emojiList = emojis.split(',')
		emojiMarkup = (
			<HStack>
				{emojiList.map((emoji: string, i) => {
					return (
						<Button
							key={i}
							bg="white"
							onClick={() => setCurrentMessage(currentMessage + emoji)}
						>
							{emoji}
						</Button>
					)
				})}
			</HStack>
		)
	} else {
		emojiMarkup = <p>Start typing to see emoji suggestions</p>
	}

	if (selectedUsername) {
		return (
			<VStack w={'100%'} h="90%" justify={'space-between'}>
				<Box px={2} w={'100%'} overflowY="auto" py="20px">
					{messagesMarkup}
					<div ref={messagesEndRef} />
				</Box>
				<Box
					spacing={0}
					bg="white"
					w={'100%'}
					position={['sticky', '-webkit-sticky']}
					bottom={0}
				>
					<Box position={['sticky', '-webkit-sticky']} bottom={0} px={2}>
						{emojiMarkup}
					</Box>
					<HStack spacing={0} bg="white" w={'100%'} py={2} px={2}>
						<Textarea
							backgroundColor={'brand.grey.100'}
							minH="unset"
							overflow="scrollY"
							w="100%"
							resize="none"
							minRows={1}
							maxRows={3}
							as={ResizeTextarea}
							value={currentMessage}
							onChange={(e) => setCurrentMessage(e.target.value)}
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									event.preventDefault()
									submitMessage()
								}
							}}
						/>
						<IconButton
							onClick={submitMessage}
							variant="unstyled"
							color="brand.blue"
							aria-label="Send message"
							icon={<FontAwesomeIcon icon={faPaperPlane} />}
						/>
					</HStack>
				</Box>
			</VStack>
		)
	} else {
		return (
			<Box my="auto" pt={20}>
				<Text color="brand.grey.300">Please select a conversation</Text>
			</Box>
		)
	}
}

export default Conversation
