import { useEffect, useRef, useState } from "react";
import { History } from "history";
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Link,
  Heading,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  VStack,
} from "@chakra-ui/react";

interface RegisterProps {
  history: History;
}
function Register(props: RegisterProps) {
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  interface errorsType {
    username?: string;
    password?: string;
    name?: string;
    confirmPassword?: string;
  }
  const [errors, setErrors]: [errorsType, any] = useState({});

  const REGISTER_USER = gql`
    mutation register($username: String!, $name: String!, $password: String!) {
      register(username: $username, name: $name, password: $password) {
        username
      }
    }
  `;
  const [registerUser, { loading }] = useMutation(REGISTER_USER, {
    update: (_, __) => {
      props.history.push("/login");
    },
    onError: (err) => {
      setErrors(err.graphQLErrors[0].extensions.errors);
    },
  });

  const passwordsMatch = () => {
    const isMatch = password === confirmPassword;
    setErrors({
      ...errors,
      confirmPassword: !isMatch ? "Passwords do not match" : null,
    });
    return isMatch;
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    if (passwordsMatch()) {
      registerUser({
        variables: {
          name,
          username,
          password,
          confirmPassword,
        },
      });
    }
  };

  //autofocus username field without errors
  const fullNameField = useRef(null);
  useEffect(() => {
    setTimeout(function () {
      if (fullNameField.current) {
        fullNameField.current.focus();
      }
    }, 100);
  }, []);

  return (
    <VStack
      align="stretch"
      textAlign="center"
      mx="auto"
      p={10}
      pl={4}
      pr={4}
      spacing={8}
      maxW={{
        lg: "750",
      }}
    >
      <Box textAlign="center">
        <Heading>Register</Heading>
      </Box>
      <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg">
        <form>
          <FormControl isRequired isInvalid={errors.name != null}>
            <FormLabel>Name</FormLabel>
            <Input
              ref={fullNameField}
              placeholder="Full Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl mt={6} isRequired isInvalid={errors.username != null}>
            <FormLabel>Username</FormLabel>
            <Input
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            <FormErrorMessage>{errors.username}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired mt={6} isInvalid={errors.password != null}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="*******"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            mt={6}
            isInvalid={errors.confirmPassword != null}
          >
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="password"
              placeholder="*******"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />
            <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
          </FormControl>
          <Button
            width="full"
            mt={4}
            type="submit"
            onClick={submitForm}
            disabled={loading}
          >
            Sign Up
          </Button>
        </form>
      </Box>
      <Box align="center">
        <Link href="/login">{"Already have an account? Sign In"}</Link>
      </Box>
    </VStack>
  );
}

export default Register;
