import {
  Text,
  HStack,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { Dictionary as DictionaryType, Message } from "../types/interfaces";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { Definition } from "../types/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { isSentimentAppVersion } from "../utils/utils";

interface DictionaryProps {
  data: DictionaryType;
  defQueryIDs: number[];
  setDict: React.Dispatch<any>;
  message: Message;
  closeStatsMenu: any;
}

const GET_DEFINITIONS = gql`
  query getEmojiDefs($emoji_ids: [Int]!) {
    getEmojiDefs(emoji_ids: $emoji_ids) {
      emoji_id
      emoji_symbol
      def_1
      def_2
      def_3
    }
  }
`;

function Dictionary(props: DictionaryProps) {
  const dictEndRef = useRef(null);
  const [defintions, setDefintions]: [Definition[], any] = useState(
    [] as Definition[]
  );
  const [getDefintions, { loading: loading, data: data }] =
    useLazyQuery(GET_DEFINITIONS);

  useEffect(() => {
    scrollToBottom();
    if (props.defQueryIDs.length > 0 && !isNaN(props.defQueryIDs[0])) {
      getDefintions({
        variables: { emoji_ids: props.defQueryIDs },
      });
    }
  }, []);

  useEffect(() => {
    if (data && data.getEmojiDefs) {
      setDefintions(data.getEmojiDefs);
      // console.log(data.getEmojiDefs);
    }
  }, [data]);

  const scrollToBottom = async () => {
    if (dictEndRef.current) {
      await new Promise((res) => setTimeout(res, 100));
      dictEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }
    console.log("Scrolling");
  };

  const statsTab = (
    <TabPanel>
      {isSentimentAppVersion() ? (
        <>
          <Text bg="white" p="3" my="2" borderRadius="10">
            Sentiment: {props.message.sentiment ?? "Unavailable"}
          </Text>
          <Text bg="white" p="3" my="2" borderRadius="10">
            Original Emotion: {props.message.emotionOriginal ?? "Unavailable"}
          </Text>
          <Text bg="white" p="3" my="2" borderRadius="10">
            Simplified Emotion: {props.message.emotion ?? "Unavailable"}
          </Text>
        </>
      ) : (
        <>
          <Text bg="white" p="3" my="2" borderRadius="10">
            Original Emotion: {props.message.emotionOriginal ?? "Unavailable"}
          </Text>
          <Text bg="white" p="3" my="2" borderRadius="10">
            Simplified Emotion: {props.message.emotion ?? "Unavailable"}
          </Text>
          <Text bg="white" p="3" my="2" borderRadius="10">
            Sentiment: {props.message.sentiment ?? "Unavailable"}
          </Text>
        </>
      )}
      <Text bg="white" p="3" my="2" borderRadius="10">
        Related Emojis:{" "}
        {props.message.emojis?.replaceAll(",", "") ?? "Unavailable"}
      </Text>
    </TabPanel>
  );

  return (
    <>
      <div ref={dictEndRef} />
      <Tabs
        onChange={() => scrollToBottom()}
        defaultIndex={props.message.hasDefinition ? 1 : 0}
        bg="brand.grey.100"
        variant="enclosed"
        my={3}
        borderRadius={15}
      >
        <TabList>
          <Tab
            // color="white"
            _selected={{
              bg: "brand.grey.200",
              boxShadow: "none",
            }}
          >
            Stats
          </Tab>
          {defintions.map((def: Definition, i: number) => {
            return (
              <Tab
                _selected={{
                  bg: "brand.grey.200",
                  boxShadow: "none",
                }}
                key={i}
              >
                {def.emoji_symbol}
              </Tab>
            );
          })}
          <HStack justify={"end"} w="100%">
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                margin: "15px",
                fontSize: "1.5rem",
              }}
              onClick={() => props.closeStatsMenu()}
            />
          </HStack>
        </TabList>
        <TabPanels>
          {statsTab}
          {defintions.map((def: Definition, i: number) => {
            return (
              <TabPanel key={i}>
                {[def.def_1, def.def_2, def.def_3].map((def, i) => {
                  if (def) {
                    return (
                      <Text key={i} bg="white" p="3" my="2" borderRadius="10">
                        {i + 1}. {def}
                      </Text>
                    );
                  }
                })}
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </>
  );
}

export default Dictionary;
