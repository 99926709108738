import { useEffect, useRef, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import {
  Box,
  Link,
  Heading,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  VStack,
} from "@chakra-ui/react";

import { useAuthDispatch } from "../context/auth";

interface LoginProps {}
function Login(props: LoginProps) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  interface errorsType {
    username?: string;
    password?: string;
  }
  const [errors, setErrors]: [errorsType, any] = useState({});

  const dispatch = useAuthDispatch();
  const LOGIN_USER = gql`
    query login($username: String!, $password: String!) {
      login(username: $username, password: $password) {
        username
        name
        token
      }
    }
  `;
  const [loginUser, { loading }] = useLazyQuery(LOGIN_USER, {
    onError: (err) => {
      console.log(err);
      setErrors(err.graphQLErrors[0].extensions.errors);
    },
    onCompleted(data) {
      dispatch({ type: "LOGIN", payload: data.login });
      window.location.href = "/";
    },
  });

  const submitForm = (e) => {
    e.preventDefault();
    loginUser({ variables: { username, password } });
  };

  //autofocus username field without errors
  const usernameField = useRef(null);
  useEffect(() => {
    setTimeout(function () {
      if (usernameField.current) {
        usernameField.current.focus();
      }
    }, 100);
  }, []);

  return (
    <VStack
      align="stretch"
      textAlign="center"
      mx="auto"
      p={10}
      pl={4}
      pr={4}
      spacing={8}
      maxW={{
        lg: "750",
      }}
    >
      <Box textAlign="center">
        <Heading>Login</Heading>
      </Box>
      <Box p={8} borderWidth={1} borderRadius={8} boxShadow="lg">
        <form>
          <FormControl isRequired isInvalid={errors.username != null}>
            <FormLabel>Username</FormLabel>
            <Input
              ref={usernameField}
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            <FormErrorMessage>{errors.username}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired mt={6} isInvalid={errors.password != null}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="*******"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
          <Button
            width="full"
            mt={4}
            type="submit"
            onClick={submitForm}
            disabled={loading}
          >
            Sign In
          </Button>
        </form>
      </Box>
      <Box align="center">
        <Link href="/register">{"Don't have an account? Sign Up"}</Link>
      </Box>
    </VStack>
  );
}

export default Login;
