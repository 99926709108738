import { useEffect, useRef, useState } from "react";
import { HStack, Text } from "@chakra-ui/react";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { Message as MessageType, User } from "../types/interfaces";
import { useAuthState } from "../context/auth";
import { Token, Dictionary as DictionaryType } from "../types/interfaces";
import Dictionary from "../components/Dictionary";
import { emotionColors, sentimentColors } from "../constants";
import { isSentimentAppVersion } from "../utils/utils";

interface MessageProps {
  message: MessageType;
  dict: DictionaryType;
  setDict: React.Dispatch<any>;
}

function Message(props: MessageProps) {
  const { decodedUserToken }: { decodedUserToken: Token } = useAuthState();
  const isSent = props.message.sender === decodedUserToken.username;

  const [hovering, setHovering] = useState(false);

  // useEffect(() => {
  //   // if (props.dict.messageID) {
  //   //   setHovering(props.dict.messageID == props.message.id);
  //   // }
  //   if (props.dict) {
  //     setHovering(props.dict.messageID == props.message.id);
  //     console.log("Changing");
  //   }
  // }, [props.dict]);

  const closeStatsMenu = () => {
    if (!props.dict || props.dict.messageID != props.message.id) {
      props.setDict({
        messageID: props.message.id,
        definitions: [],
      } as DictionaryType);
    } else {
      props.setDict(null);
    }
  };

  return (
    <>
      <HStack
        mt={"6px"}
        justify={isSent ? "flex-end" : "flex-start"}
        onMouseOver={(_) => {
          setHovering(true);
        }}
        onMouseOut={(_) => {
          setHovering(false);
        }}
        onClick={() => console.log(props.message)}
      >
        <HStack flexDirection={isSent ? "row-reverse" : "row"} spacing={0}>
          <HStack ml={isSent && 3} mr={!isSent && 3} spacing={0}>
            {!isSent && (
              <Text
                color={isSent ? "white" : "black"}
                backgroundColor={
                  isSentimentAppVersion()
                    ? sentimentColors[props.message.sentiment]
                    : emotionColors[props.message.emotion]
                }
                py={"7px"}
                px={"1px"}
                borderLeftRadius={12}
              >
                &nbsp;
              </Text>
            )}
            <Text
              color={isSent ? "white" : "black"}
              backgroundColor={isSent ? "brand.blue" : "brand.grey.100"}
              p={"7px"}
              borderRadius={(isSent || !props.message.emotion) && 12}
              borderRightRadius={!isSent && 12}
            >
              {props.message.content}
            </Text>
          </HStack>

          {
            // props.message.hasDefinition &&
            !isSent && (
              <FontAwesomeIcon
                icon={faInfoCircle}
                color={"#878787"}
                style={{
                  display: !hovering && "none",
                  marginRight: !isSent && 7,
                  marginLeft: isSent && 7,
                }}
                onClick={() => {
                  closeStatsMenu();
                }}
              />
            )
          }

          <Moment
            format="M/D h:mmA"
            style={{ whiteSpace: "nowrap", display: !hovering && "none" }}
          >
            {props.message.timestamp}
          </Moment>
        </HStack>
      </HStack>
      {props.dict && props.dict.messageID == props.message.id && (
        <Dictionary
          closeStatsMenu={closeStatsMenu}
          defQueryIDs={
            props.message.hasDefinition
              ? props.message.defQueryIds.split(" ").map((x) => parseInt(x))
              : []
          }
          data={props.dict}
          setDict={props.setDict}
          message={props.message}
        />
      )}
    </>
  );
}

export default Message;
