import React, { useState, useEffect } from "react";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { Box, Divider, Text } from "@chakra-ui/react";

import ChatPreview from "./ChatPreview";
import SearchBar from "./SearchBar";
import { User } from "../types/interfaces";
import { useMessageDispatch, useMessageState } from "../context/messages";

interface ChatPreviewsProps {}
function ChatPreviews(props: ChatPreviewsProps) {
  const dispatch = useMessageDispatch();
  const { users }: { users: User[] } = useMessageState();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSearchResults, setFilteredSearchResults] = useState(
    [] as User[]
  );

  enum UserListState {
    previousConversations,
    filteredUsers,
    globalUsers,
  }
  const [userListState, setUserListState] = useState(
    UserListState.previousConversations
  );

  const GET_CHAT_PREVIEWS = gql`
    query getChatPreviews {
      getChatPreviews {
        name
        username
        currentEmotion
        currentSentiment
        latestMessage {
          id
          sender
          recipient
          content
          timestamp
          emotion
          emojis
          emotionOriginal
          sentiment
        }
      }
    }
  `;
  const { loading } = useQuery(GET_CHAT_PREVIEWS, {
    onCompleted: (data) => {
      if (data) {
        dispatch({ type: "SET_USERS", payload: data.getChatPreviews });
        setSearchQuery("");
      }
    },
    onError: (err) => console.log(err),
  });

  const GET_NEW_USERS = gql`
    query getNewUsers($searchQuery: String!) {
      getNewUsers(searchQuery: $searchQuery) {
        username
        name
      }
    }
  `;
  const [getNewUsers, { loading: globalUsersLoading, data: globalUsers }] =
    useLazyQuery(GET_NEW_USERS);

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      const filteredUsers = users.filter(
        (user) =>
          user.name.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          user.username.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
      if (filteredUsers.length > 0) {
        setFilteredSearchResults(filteredUsers);
        setUserListState(UserListState.filteredUsers);
      } else {
        // Lookup users who we have not talked to yet
        setUserListState(UserListState.globalUsers);
        getNewUsers({ variables: { searchQuery } });
      }
    } else {
      setFilteredSearchResults([]);
      setUserListState(UserListState.previousConversations);
    }
  }, [searchQuery]);

  const chooseUserList = () => {
    if (userListState === UserListState.filteredUsers) {
      return filteredSearchResults;
    } else if (userListState === UserListState.globalUsers) {
      return [];
    } else {
      return users;
    }
  };

  return (
    <Box width={"100%"} mt={4}>
      <SearchBar search={searchQuery} setSearch={setSearchQuery} />
      {chooseUserList().map((user: User, i: number) => (
        <React.Fragment key={i}>
          <Box
            px={2}
            py={4}
            _hover={{
              background: "brand.grey.200",
            }}
            onClick={(_) => {
              dispatch({
                type: "SET_SELECTED_USER",
                payload: {
                  username: user.username,
                },
              });
              setSearchQuery("");
            }}
          >
            <ChatPreview user={user} />
          </Box>
          <Divider orientation="horizontal" />
        </React.Fragment>
      ))}

      {globalUsers != null && (
        <Box
          display={
            userListState === UserListState.globalUsers ? "inline" : "none"
          }
        >
          {globalUsers.getNewUsers.length > 0 ? (
            <Text>Global Users:</Text>
          ) : (
            <Text align="center" color="brand.grey.300">
              No Global Users Found
            </Text>
          )}
          {globalUsers.getNewUsers.map((user: User, i: number) => (
            <React.Fragment key={i}>
              <Box
                py={4}
                _hover={{
                  background: "brand.grey.200",
                }}
                onClick={(_) => {
                  dispatch({
                    type: "SET_USERS",
                    payload: [
                      user,
                      ...(users && users.length > 0 ? users : []),
                    ],
                  });
                  dispatch({
                    type: "SET_SELECTED_USER",
                    payload: {
                      username: user.username,
                    },
                  });
                  setSearchQuery("");
                }}
              >
                <ChatPreview user={user} />
              </Box>
              <Divider orientation="horizontal" />
            </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default ChatPreviews;
