// export const emotionColors: { [key: string]: string } = {
//   happy: "#f1c40f",
//   sad: "#2980b9",
//   anger: "#c0392b",
//   disgust: "#2ecc71",
//   fear: "#2c3e50",
//   neutral: "#7f8c8d",
//   surprise: "#9b59b6",
// };

export const emotionColors: { [key: string]: string } = {
  anger: "#c0392b",
  fear: "#9b59b6",
  surprise: "#f1c40f",
  happy: "#2ecc71",
  sad: "#2980b9",
  disgust: "#e67e22",
  neutral: "#7f8c8d",
};

export const sentimentColors: { [key: string]: string } = {
  positive: "#2ecc71",
  negative: "#c0392b",
  ambiguous: "#7f8c8d",
};
