import { useEffect, useState } from 'react'
import {
	VStack,
	HStack,
	Avatar,
	IconButton,
	Spacer,
	Text,
	Modal,
	useBreakpointValue,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	Box,
	ModalBody,
	ModalCloseButton,
} from '@chakra-ui/react'
import { EditIcon, SettingsIcon, ArrowBackIcon } from '@chakra-ui/icons'
import { gql, useSubscription } from '@apollo/client'

import ChatPreviews from '../components/ChatPreviews'
import Settings from '../pages/Settings'
import Conversation from '../components/Conversation'
import { User, Token } from '../types/interfaces'
import { useAuthState } from '../context/auth'
import { useMessageState, useMessageDispatch } from '../context/messages'
import EmotionAvatar from '../components/EmotionAvatar'

interface HomeProps {}
function Home(props: HomeProps) {
	const isLargeDevice = useBreakpointValue({ md: true })
	const { user, decodedUserToken }: { user: User; decodedUserToken: Token } =
		useAuthState()
	const {
		users,
		selectedUsername,
	}: { users: User[]; selectedUsername: string } = useMessageState()
	const messageDispatch = useMessageDispatch()
	const [showSettings, setShowSettings] = useState(false)

	const NEW_MESSAGE = gql`
		subscription newMessage {
			newMessage {
				senderName
				message {
					id
					sender
					recipient
					content
					timestamp
					hasDefinition
					defQueryIds
				}
			}
		}
	`
	const { data: messageData, error: messageError } =
		useSubscription(NEW_MESSAGE)
	useEffect(() => {
		if (messageError) console.log(messageError)
		if (messageData) {
			const message = messageData.newMessage.message
			const otherUser =
				decodedUserToken.username === message.recipient
					? message.sender
					: message.recipient
			messageDispatch({
				type: 'ADD_MESSAGE',
				payload: {
					username: otherUser,
					message,
					senderName: messageData.newMessage.senderName,
				},
			})
		}
	}, [messageError, messageData])

	const FOUND_EMOTION = gql`
		subscription foundEmotion {
			foundEmotion {
				emojis
				emotion
				emotionOriginal
				sentiment
				message {
					id
					sender
				}
			}
		}
	`
	const { data: foundEmotionData, error: foundEmotionError } =
		useSubscription(FOUND_EMOTION)
	useEffect(() => {
		if (foundEmotionError) console.log(foundEmotionError)
		if (foundEmotionData) {
			const data = foundEmotionData.foundEmotion
			const emotion = data.emotion //FIXME: as Emotion
			const emojis = data.emojis
			const emotionOriginal = data.emotionOriginal
			const sentiment = data.sentiment
			const messageID = data.message.id
			const sender = data.message.sender
			messageDispatch({
				type: 'ADD_EMOTION',
				payload: {
					emojis,
					emotion,
					emotionOriginal,
					sentiment,
					messageID,
					sender,
				},
			})
		}
	}, [foundEmotionData, foundEmotionError])

	const findName = (username: string) => {
		return users.filter((user: User) => user.username === username)[0].name
	}

	const findLatestMessage = (username: string) => {
		return users.filter((user: User) => user.username === username)[0]
			.latestMessage
	}

	if (!isLargeDevice && showSettings) {
		return (
			<VStack>
				<HStack
					width="100%"
					px={4}
					py={4}
					position={['sticky', '-webkit-sticky']}
					top={0}
				>
					<IconButton
						variant={'unstyled'}
						color="brand.blue"
						aria-label="Back"
						size="sm"
						icon={<ArrowBackIcon w={6} h={6} />}
						onClick={() => setShowSettings(false)}
					/>
					<Text fontSize={'1.6em'} fontWeight={'400'} pl={4}>
						Settings
					</Text>
				</HStack>
				<Settings />
			</VStack>
		)
	} else {
		return (
			<HStack spacing={0}>
				<VStack
					backgroundColor={'brand.grey.100'}
					height={'100vh'}
					overflowY="auto"
					width={'32%'}
					minWidth={'250px'}
					maxWidth={'350px'}
					display={['none', 'none', 'inline']}
				>
					<ChatPreviews />
				</VStack>
				<VStack width="100%" height={window.innerHeight} spacing={0}>
					<HStack
						bg="white"
						height="10%"
						zIndex={10}
						width="100%"
						px={2}
						py={2}
						position={['sticky', '-webkit-sticky']}
						top={0}
						// sx={{ "touch-action": "none" }}
					>
						{!isLargeDevice && selectedUsername && (
							<IconButton
								variant={'unstyled'}
								color="brand.blue"
								aria-label="Back"
								size="sm"
								icon={<ArrowBackIcon w={6} h={6} />}
								onClick={(_) => {
									messageDispatch({
										type: 'SET_SELECTED_USER',
										payload: null,
									})
								}}
							/>
						)}
						<EmotionAvatar
							name={selectedUsername ? findName(selectedUsername) : user.name}
							latestMessage={
								selectedUsername ? findLatestMessage(selectedUsername) : null
							}
							username={
								selectedUsername ? selectedUsername : decodedUserToken.username
							}
							onClick={() => {
								if (!selectedUsername && !isLargeDevice) {
									setShowSettings(true)
								}
							}}
						/>

						<Text fontSize={'1.6em'} fontWeight={'400'}>
							{selectedUsername ? findName(selectedUsername) : 'Messages'}
						</Text>
						<Spacer />
						{!selectedUsername && (
							<IconButton
								aria-label="Compose Message"
								size="md"
								icon={<EditIcon />}
								onClick={(_) => {
									messageDispatch({
										type: 'TOGGLE_SEARCH',
										payload: true,
									})
								}}
							/>
						)}
						{isLargeDevice && (
							<IconButton
								aria-label="Settings"
								size="md"
								icon={<SettingsIcon />}
								onClick={(_) => {
									setShowSettings(true)
								}}
							/>
						)}
					</HStack>
					{isLargeDevice && <Conversation />}
					{!isLargeDevice && selectedUsername && <Conversation />}
					{!isLargeDevice && !selectedUsername && <ChatPreviews />}
					<Modal
						closeOnEsc
						isOpen={showSettings}
						onClose={() => setShowSettings(false)}
					>
						<ModalOverlay />
						<ModalContent>
							<ModalHeader>Settings</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								<Settings />
							</ModalBody>
						</ModalContent>
					</Modal>
				</VStack>
			</HStack>
		)
	}
}

export default Home
