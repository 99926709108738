import { Avatar, HStack, VStack, Text, Spacer } from "@chakra-ui/react";
import Moment from "react-moment";
import { gql, useSubscription } from "@apollo/client";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { User } from "../types/interfaces";
import EmotionAvatar from "./EmotionAvatar";
import { useEffect, useState } from "react";
interface ChatPreviewProps {
  user: User;
}
function ChatPreview(props: ChatPreviewProps) {
  // const { decodedUserToken }: { decodedUserToken: Token } = useAuthState();

  //FIXME: can prlly avoid having multiple subscriptions to the same message
  const NEW_MESSAGE = gql`
    subscription newMessage {
      newMessage {
        id
        sender
        recipient
        content
        timestamp
      }
    }
  `;
  const { data: messageData, error: messageError } = useSubscription(
    NEW_MESSAGE
  );

  return (
    <HStack spacing={0}>
      <EmotionAvatar
        name={props.user.name}
        username={props.user.username}
        latestMessage={props.user.latestMessage}
      />
      <VStack align="stretch" spacing={0.3}>
        <Text fontSize={"1.05em"} fontWeight={500}>
          {props.user.name}
        </Text>
        <Text fontSize={"0.92em"} fontWeight={500}>
          {props.user.username}
        </Text>
        {props.user.latestMessage && (
          <Text color={"brand.grey.300"} fontSize={"0.85em"} noOfLines={1}>
            {props.user.latestMessage.content ?? ""}
          </Text>
        )}
      </VStack>
      <Spacer />
      {props.user.latestMessage && (
        <Text color={"brand.grey.300"} fontSize={"0.75em"}>
          <Moment format="M/D h:mmA" style={{ whiteSpace: "nowrap" }}>
            {props.user.latestMessage.timestamp ?? ""}
          </Moment>
        </Text>
      )}
      {/* {isUnread && (
        <Box pl={2} color={"brand.blue"}>
          <FontAwesomeIcon icon={faCircle} />
        </Box>
      )} */}
    </HStack>
  );
}

export default ChatPreview;
