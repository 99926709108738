import { BrowserRouter, Switch } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import "@fontsource/noto-sans";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import ApolloProvider from "./ApolloProvider";
import { AuthProvider } from "./context/auth";
import { MessageProvider } from "./context/messages";
import DynamicRoute from "./utils/DynamicRoute";
import theme from "./theme";

function App() {
  return (
    <ApolloProvider>
      <AuthProvider>
        <MessageProvider>
          <ChakraProvider theme={theme}>
            <BrowserRouter>
              <Switch>
                <DynamicRoute exact path="/" component={Home} authenticated />
                <DynamicRoute path="/register" component={Register} guest />
                <DynamicRoute path="/login" component={Login} />
              </Switch>
            </BrowserRouter>
          </ChakraProvider>
        </MessageProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
