import { Button, VStack } from "@chakra-ui/react";
import { useAuthDispatch } from "../context/auth";

interface SettingsProps {}
function Settings(props: SettingsProps) {
  const authDispatch = useAuthDispatch();
  const logout = () => {
    authDispatch({
      type: "LOGOUT",
    });
  };

  return (
    <VStack alignItems="center">
      <Button onClick={logout}>Log Out</Button>
    </VStack>
  );
}

export default Settings;
