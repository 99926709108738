import { Route, Redirect } from "react-router-dom";
import { useAuthState } from "../context/auth";

export default function DynamicRoute(props: any) {
  const { decodedUserToken } = useAuthState();

  if (props.authenticated && !decodedUserToken) {
    return <Redirect to="/login" />;
  } else if (props.guest && decodedUserToken) {
    return <Redirect to="/" />;
  } else {
    return <Route component={props.component} {...props} />;
  }
}
