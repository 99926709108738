import { createContext, useReducer, useContext } from "react";
import { User, Message } from "../types/interfaces";
import clone from "lodash/clone";

const MessageStateContext = createContext(null);
const MessageDispatchContext = createContext(null);

const messageReducer = (state: any, action: any) => {
  let usersCopy: User[] = [...state.users];
  let userIdx: number = -1;
  let messageIdx: number = -1;

  console.log(`Running action ${action.type}`);

  switch (action.type) {
    // Called when ChatPreviews.tsx is rendered
    case "SET_USERS":
      usersCopy = [...action.payload];
      usersCopy.map((u: User) => (u.messages = [] as Message[])); // messages are dynamically loaded when a user is selected

      return {
        ...state,
        users: usersCopy,
      };

    // Called in Conversation.tsx whenever selectedUser changes
    case "SET_USER_MESSAGES":
      // usersCopy = [...state.users];
      userIdx = usersCopy.findIndex(
        (u: User) => u.username === action.payload.username
      );
      if (userIdx !== -1) {
        usersCopy[userIdx].messages = action.payload.messages;
        let userCurrentEmotion = null;
        let userCurrentSentiment = null;

        const reverseMessages = action.payload.messages.slice().reverse();
        for (let i = 0; i < action.payload.messages.length; i++) {
          const msg: Message = reverseMessages[i];
          if (msg.sender == action.payload.username) {
            userCurrentEmotion = msg.emotion;
            userCurrentSentiment = msg.sentiment;
            break;
          }
        }

        usersCopy[userIdx].currentEmotion = userCurrentEmotion;
        usersCopy[userIdx].currentSentiment = userCurrentSentiment;
      }
      return {
        ...state,
        users: usersCopy,
      };

    // Called by ChatPrevews.tsx and by Home.sx (to deselect all users)
    case "SET_SELECTED_USER":
      let selectedUsername: string = "";
      if (action.payload && action.payload.username) {
        selectedUsername = action.payload.username;
      }
      console.log(`Selecting ${selectedUsername}`);
      return {
        ...state,
        // users: usersCopy,
        selectedUsername: selectedUsername,
      };

    // Called by Home.tsx when new message subscription fired
    case "ADD_MESSAGE":
      // usersCopy = [...state.users];
      userIdx = state.users.findIndex(
        (u: User) => u.username === action.payload.username
      );

      // Get a message from a new user
      if (userIdx === -1) {
        console.log(action.payload.senderName);

        let newUser = {
          name: action.payload.senderName,
          username: action.payload.username,
          latestMessage: action.payload.message,
          messages: [action.payload.message as Message],
        };
        usersCopy = [newUser, ...usersCopy];
      } else {
        let updatedUser = {
          ...usersCopy[userIdx],
          latestMessage: action.payload.message,
          messages: [
            ...usersCopy[userIdx].messages,
            action.payload.message as Message,
          ],
        };
        usersCopy[userIdx] = updatedUser;
      }

      return {
        ...state,
        users: usersCopy,
      };
    case "ADD_EMOTION":
      userIdx = state.users.findIndex(
        (u: User) => u.username === action.payload.sender
      );
      messageIdx = state.users[userIdx].messages.findIndex(
        (msg: Message) => msg.id === action.payload.messageID
      );

      let updatedMessage: Message = clone(
        state.users[userIdx].messages[messageIdx]
      );

      if (!updatedMessage) {
        console.log(`Could not find updated message:`);
        console.log(state.users);
        console.log(userIdx);
        console.log(messageIdx);
      }

      if (messageIdx !== -1) {
        updatedMessage.emotion = action.payload.emotion;
        updatedMessage.emojis = action.payload.emojis;
        updatedMessage.emotionOriginal = action.payload.emotionOriginal;
        updatedMessage.sentiment = action.payload.sentiment;
        // usersCopy = [...state.users];
        let updatedMessages = [...usersCopy[userIdx].messages];
        updatedMessages[messageIdx] = updatedMessage;

        let updatedUser = {
          ...usersCopy[userIdx],
          messages: updatedMessages,
          currentEmotion: action.payload.emotion,
          currentSentiment: action.payload.sentiment,
        };
        usersCopy[userIdx] = updatedUser;
      } else {
        console.log("Could not find message to update");
      }

      return {
        ...state,
        users: usersCopy,
      };
    case "TOGGLE_SEARCH":
      const focus: boolean = action.payload;
      return {
        ...state,
        focusSearchBar: focus, // maybe toggle?
      };

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export const MessageProvider = ({ children }: { children: any }) => {
  const initState = {
    users: [],
    focusSearchBar: false,
    selectedUsername: "",
  };
  const [state, dispatch] = useReducer(messageReducer, initState);

  return (
    <MessageDispatchContext.Provider value={dispatch}>
      <MessageStateContext.Provider value={state}>
        {children}
      </MessageStateContext.Provider>
    </MessageDispatchContext.Provider>
  );
};

export const useMessageState = () => useContext(MessageStateContext);
export const useMessageDispatch = () => useContext(MessageDispatchContext);
